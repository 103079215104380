<script>
export default {
  props: {
    value: {
      type: [String, undefined],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.formatPrice(this.value);
      },
      set(value) {
        if (!this.disabled) {
          let numberValue = `${!value ? '' : Number(value.replace(",", "."))}`

          if (+numberValue < 0) {
            numberValue = Math.abs(+numberValue);
          }

          this.$emit('input', `${numberValue}`);
        }
      },
    }
  },
  methods: {
    formatPrice(value) {
      if (!value) return '';
      const cleanValue = value.replace(/[^0-9,.]*/g, '').replace('.', ',');
      const [integerPart, decimalPart] = cleanValue.split('.');
      return decimalPart ? `${integerPart},${decimalPart.slice(0, 2)}` : integerPart;
    },
    onBeforeInput(event) {
      if (this.disabled) {
        event.preventDefault();
        return
      }

      if (event.inputType.startsWith('delete') ||
        event.inputType.startsWith('insert') && event.inputType !== 'insertText') {
        return
      }

      const [_, decimalPart] = this.value.split('.');
      if (decimalPart && decimalPart.length >= 2) {
        event.preventDefault();
        return
      }

      if (event.inputType === 'insertText') {
        if (!/^[0-9,.]$/.test(event.data)) {
          event.preventDefault();
          return
        }

        if ((event.data === ',' || event.data === '.') &&
          (this.value.includes(',') || this.value.includes('.'))) {
          event.preventDefault();
        }
      }
    },
  }
};
</script>

<template>
  <div class="price-input" :class="{dirty: this.value}">
    <input
      type="text"
      class="form-control"
      :class="{error}"
      v-model="model"
      @beforeinput="onBeforeInput"
      :placeholder="placeholder"
      :disabled="disabled"
    />
  </div>
</template>

<style scoped lang="scss">
.price-input {
  position: relative;
  display: flex;

  &.dirty {
    &::after {
      display: flex;
      content: '€';
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      line-height: 14px;
      height: 14px;
    }
    .form-control {
      padding-left: 23px;
    }
  }

  .form-control {
    font-size: 14px;
    line-height: 14px;
    height: 35px;
    padding-top: 0;
    padding-bottom: 0;

    &.error {
      border-color: rgba(255, 0, 0, 0.8);
      box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;

      &:active,
      &:focus {
        border-color: rgba(255, 0, 0, 0.8);
        box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;
      }
    }
  }
}
</style>
