<template>
  <form
    @submit.prevent="addCredit"
    class="collapse show"
    id="addCredit"
    v-if="show || showModal"
  >
    <div class="card">
      <h4 class="card-header">{{ activeLanguage.title }}</h4>
      <div class="card-body pt-0 p-0">
        <h3>{{ activeLanguage.step1 }}</h3>
        <div class="padding pb-3">
          <div class="form-check" style="padding-left: 0" id="creditCheckType">
            <label class="custom-control custom-checkbox form-check-label">
              <input
                type="radio"
                name="radio"
                id="id1"
                value="1"
                @change="changePaymentType('full')"
                class="custom-control-input form-check-input mr-2"
              />
              <span class="custom-control-indicator"></span>
              {{ activeLanguage.full }}
            </label>
          </div>

          <div class="form-check" style="padding-left: 0">
            <label class="custom-control custom-checkbox form-check-label">
              <input
                type="radio"
                name="radio"
                id="id2"
                value="1"
                @change="changePaymentType('fixed')"
                class="custom-control-input form-check-input mr-2"
              />
              <span class="custom-control-indicator"></span>
              {{ activeLanguage.part }}
            </label>
          </div>

          <div class="form-check" style="padding-left: 0">
            <label class="custom-control custom-checkbox form-check-label">
              <input
                type="radio"
                name="radio"
                id="id3"
                value="1"
                @change="changePaymentType('product')"
                class="custom-control-input form-check-input mr-2"
              />
              <span class="custom-control-indicator"></span>
              {{ activeLanguage.product }}
            </label>
          </div>

          <div
            class="form-check"
            style="padding-left: 0"
            v-if="!!canCreditWithReturnCosts"
          >
            <label class="custom-control custom-checkbox form-check-label">
              <input
                type="radio"
                name="radio"
                id="id4"
                value="1"
                @change="changePaymentType('can_credit_with_return_costs')"
                class="custom-control-input form-check-input mr-2"
              />
              <span class="custom-control-indicator"></span>
              {{ activeLanguage.returncosts }}
            </label>
          </div>

          <div class="form-check" style="padding-left: 0">
            <label class="custom-control custom-checkbox form-check-label">
              <input
                type="radio"
                name="radio"
                id="id5"
                value="1"
                @change="changePaymentType('outstandingCredit')"
                class="custom-control-input form-check-input mr-2"
              />
              <span class="custom-control-indicator"></span>
              {{ activeLanguage.outstandingCredit }}
            </label>
          </div>
        </div>

        <div
          class="collapse show"
          id="fullpayment"
          v-if="showAdd === 'full/fixed' || showAdd === 'fixed'"
        >
          <h3>{{ activeLanguage.step2 }}</h3>
          <div class="padding">
            <div class="form-row">
              <div class="form-group col-md-8">
                <label for="note">{{ activeLanguage.note }}</label>
                <textarea
                  v-model="description"
                  class="form-control"
                  id="note"
                  rows="2"
                  style="resize: none"
                ></textarea>
              </div>
              <div class="form-group col-md-4">
                <label for="amount">{{ activeLanguage.amount }}</label>
                <template v-if="!!fixed">
                  <InputPrice
                    :value="amount"
                    @input="checkAmount"
                    :placeholder="activeLanguage.enterAmount"
                    :disabled="!!fixed"
                  />
                </template>
                <template v-else>
                  <InputPrice
                    v-model="amount"
                    @input="checkAmount"
                    :placeholder="activeLanguage.enterAmount"
                    :error="wrongAmount"
                  />
                </template>
                <span class="wrongAmount" v-if="wrongAmount">
                  {{ activeLanguage.wrongAmount }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="collapse show"
          id="can_credit_with_return_costs"
          v-if="
            !!canCreditWithReturnCosts &&
            showAdd === 'can_credit_with_return_costs'
          "
        >
          <h3>{{ activeLanguage.step2 }}</h3>
          <div class="padding">
            <div class="form-row">
              <div class="form-group col-md-8">
                <label for="note">{{ activeLanguage.note }}</label>
                <textarea
                  v-model="description"
                  class="form-control"
                  id="note"
                  rows="2"
                  style="resize: none"
                ></textarea>
              </div>
              <div class="form-group col-md-4">
                <label for="amount">{{ activeLanguage.amount }}</label>
                <template v-if="!!fixed">
                  <InputPrice
                    :value="amount"
                    @input="checkAmount"
                    :placeholder="activeLanguage.enterAmount"
                    :disabled="!!fixed"
                  />
                </template>
                <template v-else>
                  <InputPrice
                    v-model="amount"
                    @input="checkAmount"
                    :placeholder="activeLanguage.enterAmount"
                    :error="wrongAmount"
                  />
                </template>
                <span class="wrongAmount" v-if="wrongAmount">
                  {{ activeLanguage.wrongAmount }}
                </span>
              </div>
            </div>
          </div>
        </div>


        <div
          class="collapse show"
          v-if="showAdd === 'product'"
          id="productCheckType"
        >
          <h3>{{ activeLanguage.step2alt }}</h3>
          <div class="padding">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-left">
                    {{ activeLanguage.productCreditTable.code }}
                  </th>
                  <th class="text-left">
                    {{ activeLanguage.productCreditTable.desc }}
                  </th>
                  <th>{{ activeLanguage.productCreditTable.num }}</th>
                  <th class="amount-title">
                    {{ activeLanguage.productCreditTable.piece }}
                  </th>
                  <th>{{ activeLanguage.productCreditTable.credit }}</th>
                  <th class="amount-title">
                    {{ activeLanguage.productCreditTable.total }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in localItems" :key="index">
                  <td class="text-left">{{ item.code }}</td>
                  <td class="text-left">{{ item.description }}</td>
                  <td>{{ items[index].total_items }}</td>
                  <td class="amount">
                    <span>€</span>
                    {{ formattedAmount(item.piece_price_inc_vat) }}
                  </td>
                  <td class="py-0">
                    <select
                      @change="creditPieceSelected($event, item)"
                      class="form-control padd-select"
                      id="select"
                    >
                      <option>0</option>
                      <option
                        v-for="num in parseFloat(items[index].total_items)"
                        :value="num"
                      >
                        {{ num }}
                      </option>
                    </select>
                  </td>
                  <td class="amount padd-total-price">
                    <span>€</span>
                    {{ formattedAmount(+item.piece_price_inc_vat * +localItems[index].total_items) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>


          <h3 class="mt-4">{{ activeLanguage.step3 }}</h3>
          <div class="padding">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="note">{{ activeLanguage.note }}</label>
                <textarea
                  v-model="description"
                  class="form-control"
                  id="note"
                  rows="2"
                  style="resize: none"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div
          class="collapse show"
          id="outstandingCredit"
          v-if="showAdd === 'outstandingCredit'"
        >
          <h3>{{ activeLanguage.step2 }}</h3>
          <div class="padding">
            <div class="form-row">
              <div class="form-group col-md-8">
                <label for="note">{{ activeLanguage.note }}</label>
                <textarea
                  v-model="description"
                  class="form-control"
                  id="note"
                  rows="2"
                  style="resize: none"
                ></textarea>
              </div>
              <div class="form-group col-md-4">
                <label for="amount">{{ activeLanguage.amount }}</label>
                <InputPrice
                  :value="amount"
                  :placeholder="activeLanguage.enterAmount"
                  disabled
                />
                <span class="wrongAmount" v-if="wrongAmount">
                  {{ activeLanguage.wrongAmount }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="padding text-right my-4">
          <a class="btn btn-secondary" href="#" @click.prevent="close">{{
            activeLanguage.closeBtn
          }}</a>
          &nbsp;
          <button
            type="submit"
            class="btn"
            :class="wrongAmount ? 'btn-secondary' : ''"
          >
            {{ activeLanguage.saveBtn }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import InputPrice from "../common/reusable/InputPrice.vue";

export default {
  name: "credit-order",
  props: {
    activeLanguage: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    orderData: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    manageOrderRight: {
      type: Boolean,
      default: false,
    },
    canCreditWithReturnCosts: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    outstanding: {
      default: false,
    },
    payment: {
      default: false,
    },
  },
  data() {
    return {
      show: false,
      showAdd: "",
      description: "",
      fixed: null,
      amount: '0',
      selectedItems: {},
      localItems: [],
      wrongAmount: false,
    };
  },
  computed: {
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount);
    },
    initialItems() {
      return this.items.map(item => ({...item}));
    },
  },
  watch: {
    initialItems: {
      immediate: true,
      handler(newItems) {
        this.localItems = newItems.map(item => ({
          ...item,
          total_items: '0'
        }));
      },
    },
  },
  methods: {
    checkAmount() {
      let { type, ex_vat } = this.orderData;
      if (type === "Company" || type === "Bedrijf") {
        this.wrongAmount =
          parseFloat(this.amount.replace(/[€ ,]/g, "")) >
          parseFloat(this.outstanding) + parseFloat(this.payment);
      } else {
        this.wrongAmount =
          parseFloat(this.amount.replace(/[€ ,]/g, "")) > this.orderData.credit;
      }
    },
    open() {
      this.show = true;
      setTimeout(() => Helper.scrollTo("addCredit", "start"), 0);
    },
    showCreditOrder() {
      this.show = true;
    },
    close() {
      this.showModal = false;
      this.show = false;
      this.showAdd = "";
      this.description = "";
      this.fixed = null;
      this.amount = 0;
      this.selectedItems = {};
      this.$emit("close");
    },

    creditPieceSelected(e, item) {
      let val = e.target.selectedOptions[0].value;
      if (parseFloat(val) >= 0) {
        item.total_items = val;
        this.selectedItems[item.id] = item;
      } else {
        delete this.selectedItems[item.id];
      }
    },
    addCredit() {
      if (this.wrongAmount) {
        return false;
      }

      let data = {
        order_id: this.orderData.order_id,
        full: this.fixed,
        desc: this.description,
      };

      if (this.showAdd === "full/fixed") {
        data.amount = parseFloat(this.amount.replace(/[€ ,]/g, ""));
      }
      if (this.showAdd === "fixed") {
        data.amount = parseFloat(this.amount.replace(/[€ ,]/g, ""));
      }
      if (this.showAdd === "can_credit_with_return_costs") {
        data.amount = parseFloat(this.amount.replace(/[€ ,]/g, ""));
        data.return_costs = true;
      }
      if (this.showAdd === "product") {
        data.product = this.selectedItems;
      }
      if (this.showAdd === "outstandingCredit") {
        data.amount = parseFloat(this.amount.replace(/[€ ,]/g, ""));
      }

      if (this.payment > 0) {
        if (!data.desc.length) {
          EventBus.$emit("showAlertModal", "Please fill Note field");
        } else if (
          !data.amount &&
          (this.showAdd === "full/fixed" || this.showAdd === "fixed")
        ) {
          EventBus.$emit("showAlertModal", "Amount field is empty");
        } else if (
          !data.amount &&
          this.showAdd === "can_credit_with_return_costs"
        ) {
          EventBus.$emit("showAlertModal", "Amount field is empty");
        } else if (
          data.hasOwnProperty("product") &&
          Object.keys(data.product).length === 0 &&
          this.showAdd === "product"
        ) {
          EventBus.$emit(
            "showAlertModal",
            this.activeLanguage.productCreditTable.errorCredit,
          );
        } else {
          billinkAxios
            .post("/app/credit/apply", data)
            .then(({ data }) => {
              if (data.status === "success") {
                this.$emit("creditIsAdded", this.$route.params.id, true);
                this.close();
              } else if (!!data.message) {
                EventBus.$emit("showAlertModal", data.message);
              }
            })
            .catch((err) => {
              EventBus.$emit("showAlertModal", err.response.data.message);
              console.error(err);
            });
        }
      } else {
        if (!data.desc.length) {
          EventBus.$emit("showAlertModal", "Please fill Note field");
        } else if (
          !data.amount &&
          (this.showAdd === "full/fixed" || this.showAdd === "fixed")
        ) {
          EventBus.$emit("showAlertModal", "Amount field is empty");
        } else if (
          !data.amount &&
          this.showAdd === "can_credit_with_return_costs"
        ) {
          EventBus.$emit("showAlertModal", "Amount field is empty");
        } else if (
          data.hasOwnProperty("product") &&
          Object.keys(data.product).length === 0 &&
          this.showAdd === "product"
        ) {
          EventBus.$emit(
            "showAlertModal",
            this.activeLanguage.productCreditTable.errorCredit,
          );
        } else {
          billinkAxios
            .post("/app/credit/apply", data)
            .then(({ data }) => {
              if (data.status === "success") {
                this.$emit("creditIsAdded", this.$route.params.id, true);
                this.close();
              } else if (!!data.message) {
                EventBus.$emit("showAlertModal", data.message);
              }
            })
            .catch((err) => {
              EventBus.$emit("showAlertModal", err.response.data.message);
              console.error(err);
            });
        }
      }
    },
    changePaymentType(type) {
      if (type === "full") {
        if (
          this.orderData.type === "Company" ||
          this.orderData.type === "Bedrijf"
        ) {
          if (this.payment > 0 && this.outstanding) {
            let fullAmount =
              parseFloat(this.outstanding) + parseFloat(this.payment);
            this.amount = "€ " + parseFloat(fullAmount).toFixed(2);
          } else if (this.payment > 0 && this.outstanding === "0") {
            this.amount = "€ " + parseFloat(this.payment).toFixed(2);
          } else {
            this.payment > 0
              ? (this.amount = "€ " + parseFloat(this.outstanding).toFixed(2))
              : (this.amount =
                  "€ " + parseFloat(this.orderData.amount).toFixed(2));
            if (this.orderData.type === "Bedrijf") {
              this.amount = "€ " + parseFloat(this.outstanding).toFixed(2);
            }
          }
        } else {
          this.amount = "€ " + parseFloat(this.orderData.credit - 0).toFixed(2);
        }
        this.showAdd = "full/fixed";
        this.fixed = 1;
      } else if (type === "fixed") {
        this.amount = "";
        this.showAdd = "fixed";
        this.fixed = 0;
      } else if (type === "product") {
        this.showAdd = "product";
        this.fixed = 0;
      } else if (type === "can_credit_with_return_costs") {
        this.showAdd = "can_credit_with_return_costs";
        this.fixed = 0;
      } else if (type === "outstandingCredit") {
        this.showAdd = "outstandingCredit";
        this.amount = `€ ${parseFloat(this.outstanding).toFixed(2)}`
        this.fixed = 0;
      }
      this.description = "";
      setTimeout(() => {
        if (type === "full" || type === "fixed") {
          Helper.scrollTo(
            type === "full" || type === "fixed"
              ? "creditCheckType"
              : "productCheckType",
            "start",
          )
        }
      }, 0,);
    },
  },
  components: {
    InputPrice,
  },
};
</script>

<style scoped lang="scss">
.form-check-label {
  cursor: pointer;
}

.custom-control-indicator {
  margin-top: -2px;
}

.wrongAmount {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  padding-left: 4px;
  display: block;
}

.wrongAmountInput {
  border-color: rgba(255, 0, 0, 0.8);
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;

  &:active,
  &:focus {
    border-color: rgba(255, 0, 0, 0.8);
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;
  }
}

.padd-select {
  padding: 0 0 0 9px;
}

.padd-total-price {
  padding: 3px;
  padding-top: 11px;
}
</style>
